<template>
  <v-container
    id="container"
    fluid
    class="d-flex align-center justify-center"
  >
    <v-row class="d-flex align-center">
      <v-col
        offset="1"
        offset-md="2"
        cols="10"
        md="8"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            role="resetPasswordForm"
            @submit.prevent="handleSubmit(resetPassword)"
          >
            <v-row
              class="text-h4 font-weight-bold mb-8"
              justify="center"
            >
              {{ $t("changePassword") }}
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("newPassword") }}
              </v-label>
              <v-text-field
                id="new_password"
                v-model="password"
                filled
                :placeholder="$t('password')"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click:append="visiblePassword = !visiblePassword"
              />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("confirmPassword") }}
              </v-label>
              <v-text-field
                id="confirm-password"
                v-model="confirmation"
                filled
                :placeholder="$t('password')"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click:append="visiblePassword = !visiblePassword"
              />
            </v-row>
            <v-row
              class="my-8 text-subtitle-1 font-weight-medium"
              align="center"
            >
              <v-checkbox class="font-weight-medium" />
              {{ $t("rememberMe") }}
            </v-row>
            <v-row class="my-8">
              <v-btn
                id="reset-password"
                block
                color="primary"
                type="button"
              >
                {{ $t("resetPassword") }}
              </v-btn>
            </v-row>
            <v-row
              class="text-subtitle-1"
              justify="center"
            >
              {{ $t("alreadyHaveAccount") }}
              <router-link
                id="sign-up-link"
                to="/login"
                class="text-decoration-none font-weight-bold fs-14 ml-6"
              >
                {{ $t("logintoyouraccount") }}
              </router-link>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeAuthService from '@/services/api/auth';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  data() {
    return {
      confirmation: '',
      password: '',
      token: null,
      visiblePassword: false,
    };
  },
  beforeCreate() {
    this.token = this.$route.query.token;
    const authService = makeAuthService(this.$api);
    authService
      .verifyEmail(this.token)
      .then(response => {
        if (response.status === 200) {
          showSuccessToast(this.$swal, 'verifiedResetlink');
        }
      })
      .catch(error => {
        showErrorToast(this.$swal, 'error', { text: error });
        this.$router.push('/login').catch(e => {
          console.log(e);
        });
      });
  },
  methods: {
    resetPassword() {
      this.token = this.$route.query.token;
      const authService = makeAuthService(this.$api);
      authService
        .resetPassword({
          token: this.token,
          password: this.password,
        })
        .then(
          response => {
            this.$router.push('/login').catch(e => {
              console.log(e);
            });
            showSuccessToast(this.$swal, 'resetPasswordSuccess', { message: response.data.message });
          },
          error => {
            showErrorToast(this.$swal, 'resetPasswordError', { text: error.response.data.error });
            this.$router.push('/login').catch(e => {
              console.log(e);
            });
          }
        );
    },
  },
};
</script>
